import axios from 'axios';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  CreateCustomer,
  Customer,
  CustomerAddress,
  CustomerAlias,
} from '@lambda/apis/src/customer/types';
import {
  CustomerAddressType,
  CustomerSubscriptionChannel,
} from '@lambda/apis/src/customer/enum';
import {
  AuthTokens,
  CognitoCustomerResponse,
  CognitoRefreshTokenResponse,
  CreateCognitoCustomer,
} from '@lambda/apis/src/customer/cognito/types';
import { logoutCustomer } from '../analytics/trackCustomer';
import { logger } from '../logger';
import {
  GoogleResponse,
  UpdateEmailSubscriptionRequest,
  UpdateSmsSubscriptionRequest,
  UseCustomerResult,
} from './types';
import { shouldUseCognito } from '@/components/commons/Customer/constants';

const COOKIE_TOKEN = 'auth-token';
const COOKIE_EXPIRY = 'auth-expire';
const COOKIE_USER = 'auth-user';
const COOKIE_ANONYMOUS_ID = 'ajs_anonymous_id';

export const COOKIE_COGNITO_REFRESH_TOKEN = 'refresh-token';
export const COOKIE_COGNITO_ID_TOKEN = 'id-token';

const useCustomer = (): UseCustomerResult => {
  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE_TOKEN,
    COOKIE_EXPIRY,
    COOKIE_USER,
    COOKIE_ANONYMOUS_ID,
    COOKIE_COGNITO_REFRESH_TOKEN,
    COOKIE_COGNITO_ID_TOKEN,
  ]);

  // Legacy - no longer needed
  if (cookies[COOKIE_EXPIRY]) removeCookie(COOKIE_EXPIRY);

  const initialSessionToken = cookies[COOKIE_TOKEN] || null;
  const initialCustomer = cookies[COOKIE_USER] || null;
  const initialIdToken = cookies[COOKIE_COGNITO_ID_TOKEN] || null;
  const [customer, setCustomer] = useState<Customer | undefined>(
    initialCustomer,
  );
  const [anonymousId, setAnonymousId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (cookies.ajs_anonymous_id) {
      logger.info(
        { anonymousId: cookies.ajs_anonymous_id },
        'Setting anonymous id',
      );

      setAnonymousId(cookies.ajs_anonymous_id);
    }
  }, [cookies]);

  const setCustomerData = (c: Customer) => {
    setCookie(COOKIE_USER, c, { path: '/' });
    setCookie(COOKIE_TOKEN, c.accessToken, { path: '/' });
    setCustomer(c);
  };

  const clearCustomerData = () => {
    removeCookie(COOKIE_USER, { path: '/' });
    removeCookie(COOKIE_TOKEN, { path: '/' });
    removeCookie(COOKIE_COGNITO_ID_TOKEN, { path: '/' });
    removeCookie(COOKIE_COGNITO_REFRESH_TOKEN, { path: '/' });
    setCustomer(undefined);
  };

  const setCognitoIdToken = (idToken: string) => {
    setCookie(COOKIE_COGNITO_ID_TOKEN, idToken, {
      path: '/',
    });
  };

  const setCognitoRefreshToken = (refreshToken: string) => {
    setCookie(COOKIE_COGNITO_REFRESH_TOKEN, refreshToken, { path: '/' });
  };

  const login = async (
    email: string,
    password: string,
  ): Promise<Customer | undefined> => {
    try {
      setError(null);
      setLoading(true);

      const response = await axios.post<Customer>('/api/customer/auth', {
        email,
        password,
      });

      if (!response.data?.accessToken) throw new Error('Authentication failed');

      setCustomerData(response.data);

      return response.data;
    } catch (err) {
      const errorMessage = 'Incorrect email or password';

      setError(errorMessage);
      logger.error({ err, errorMessage }, 'Error during login');
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const loginWithGoogle = async (
    res: GoogleResponse,
  ): Promise<Customer | undefined> => {
    try {
      setLoading(true);

      const response = await axios.post<Customer>('/api/login', res);

      if (!response.data?.reebeloId) throw new Error('Authentication failed');

      setCustomerData(response.data);

      return response.data;
    } catch (err) {
      setError('There was a problem logging in with Google. Please try again.');

      logger.error({ err }, 'Google response error');
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const logout = async () => {
    if (!customer) return;

    await axios.delete('/api/customer/auth').finally(() => {
      clearCustomerData();
      logoutCustomer();
    });
  };

  const forgotPassword = async (email: string): Promise<void> => {
    try {
      await axios.post('/api/customer/auth/forgot', { email });
    } catch (err) {
      logger.error({ err }, 'Error sending forgot password email');
    }
  };

  const resetPassword = async (
    shopifyId: string,
    token: string,
    password: string,
  ): Promise<Customer> => {
    try {
      const { data } = await axios.post<Customer>('/api/customer/auth/reset', {
        shopifyId,
        token,
        password,
      });

      setCustomerData(data);

      return data;
    } catch (err) {
      logger.error({ err }, 'Error resetting password');

      throw err;
    }
  };

  const signup = async (
    customerData: CreateCustomer,
  ): Promise<Customer | undefined> => {
    try {
      setError(null);
      setLoading(true);

      const response = await axios.post<Customer>(
        '/api/customer',
        customerData,
      );

      if (!response.data) throw new Error('Failed to create customer profile');

      return response.data;
    } catch (err) {
      let errorMessage =
        'There was a problem signing up.  Please try again later.';

      if (axios.isAxiosError(err)) {
        const responseData: { code?: number } = err.response?.data || {};

        if (responseData && responseData.code === 409) {
          errorMessage =
            'An account under this email already exists. Try resetting your password.';
        }
      }

      setError(errorMessage);
      logger.error({ err, errorMessage }, 'Error creating customer profile');
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const updateAddress = async (
    type: CustomerAddressType,
    address: CustomerAddress,
  ): Promise<Customer | undefined> => {
    try {
      setLoading(true);

      const response = await axios.put<Customer>(
        `/api/customer/address?type=${type}`,
        address,
      );

      if (!response.data) throw new Error('Failed to update address');

      setCustomerData(response.data);
    } catch (err) {
      setError(
        'There was a problem updating your address. Please try again later.',
      );

      logger.error({ err }, 'Error updating address');
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const updateEmailSubscription = async (
    req: UpdateEmailSubscriptionRequest,
  ): Promise<void> => {
    try {
      const channel = CustomerSubscriptionChannel.EMAIL;

      await axios.post('/api/customer/subscribe', {
        ...req,
        channel,
        anonymousId,
      });
    } catch (err) {
      logger.error({ err, req }, 'Error updating email subscription');
    }
  };

  const updateSmsSubscription = async (
    req: UpdateSmsSubscriptionRequest,
  ): Promise<void> => {
    try {
      const channel = CustomerSubscriptionChannel.SMS;

      await axios.post('/api/customer/subscribe', {
        ...req,
        channel,
        anonymousId,
      });
    } catch (err) {
      logger.error({ err, req }, 'Error updating sms subscription');
    }
  };

  const createAlias = async (
    customerId: string,
    alias: CustomerAlias,
  ): Promise<void> => {
    try {
      await axios.post(`/api/customer/alias`, {
        customerId,
        alias,
      });
    } catch (err) {
      logger.error({ err }, 'Error creating customer alias');
    }
  };

  const fetchCustomerData = async (): Promise<Customer | undefined> => {
    try {
      if (customer) return undefined;

      setLoading(true);

      const response = await axios.get<Customer>('/api/customer/me');

      if (!response.data) throw new Error('User not found');

      return response.data;
    } catch (err) {
      logger.error({ err }, 'Error fetching customer data');
      clearCustomerData();
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const verifyCookies = async (): Promise<void> => {
    logger.info({ initialSessionToken, initialCustomer }, 'verifying cookies');

    if (initialSessionToken && !initialCustomer) {
      const customerData = await fetchCustomerData();

      if (!customerData) return;

      setCustomerData(customerData);
    }
  };

  const verifyCognitoCookies = async (idToken?: string): Promise<void> => {
    if (!shouldUseCognito) return;
    logger.debug(
      { initialIdToken, initialCustomer },
      'verifying cognito cookies',
    );
    const _idToken = idToken || initialIdToken;

    if (_idToken && !initialCustomer) {
      const customerData = await fetchCustomerData();

      if (!customerData) return;

      setCustomerData(customerData);
    }
  };

  const isSubscribed = async (channel: 'email' | 'sms', email: string) => {
    try {
      const _isSubscribed = await axios
        .get(`/api/customer/subscribed/${email}/${channel}`)
        .then((res) => res.data);

      return _isSubscribed;
    } catch (err) {
      logger.error(
        { err, email, channel },
        'Error checking customer subscription',
      );

      return false;
    }
  };

  const createAddress = async (address: CustomerAddress) => {
    try {
      setLoading(true);
      setError(null);
      const addresses = await axios
        .post(`/api/customer/addresses`, address)
        .then((res) => res.data);

      return addresses;
    } catch (err: any) {
      logger.error({ err }, 'Error creating address');
      const errorMessage = axios.isAxiosError(err)
        ? (err.response?.data as { message: string })?.message
        : err.message;

      setError(errorMessage || 'Error creating address');
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const softDeleteAddress = async (addressId: string) => {
    try {
      if (!addressId) throw new Error('Address Id is required');
      setLoading(true);
      setError(null);
      const addresses = await axios
        .delete(`/api/customer/addresses?addressId=${addressId}`)
        .then((res) => res.data);

      return addresses;
    } catch (err: any) {
      logger.error({ err, addressId }, 'Error deleting address');

      const errorMessage = axios.isAxiosError(err)
        ? (err.response?.data as { message: string })?.message
        : err.message;

      setError(errorMessage || 'Error deleting address');
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const updateCustomerAddress = async (
    address: CustomerAddress,
    addressId: string | undefined,
  ) => {
    try {
      if (!addressId) throw new Error('Address Id is required');
      setError(null);
      setLoading(true);
      const addresses = await axios
        .put(`/api/customer/addresses?addressId=${addressId}`, address)
        .then((res) => res.data);

      return addresses;
    } catch (err: any) {
      logger.error({ err, addressId }, 'Error updating address');

      const errorMessage = axios.isAxiosError(err)
        ? (err.response?.data as { message: string })?.message
        : err.message;

      setError(errorMessage || 'Error updating address');
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyCookies();
    verifyCognitoCookies();
  }, []);

  // Cognito Login
  const cognitoLogin = async (
    email: string,
    password?: string,
  ): Promise<CognitoCustomerResponse> => {
    try {
      setError(null);
      setLoading(true);

      const response = await axios.post<CognitoCustomerResponse>(
        '/api/customer/auth/cognito/login',
        { email, password },
      );

      if (!response.data || !response.data.message)
        throw new Error('Authentication failed: No response data');

      if (response.data.idToken) setCognitoIdToken(response.data.idToken);

      if (response.data.refreshToken)
        setCognitoRefreshToken(response.data.refreshToken);

      if (response.data.customer) setCustomerData(response.data.customer);
      else if (password) throw new Error('Customer not found');

      return response.data;
    } catch (err: any) {
      const errorMessage = 'Failed to Sign in Customer';

      logger.error({ err }, errorMessage);
      if (err?.response?.data) return err.response.data;

      return { message: errorMessage, error: 'Error' };
    } finally {
      setLoading(false);
    }
  };

  // Cognito Signup
  const cognitoSignup = async (
    customerData: CreateCognitoCustomer,
  ): Promise<CognitoCustomerResponse> => {
    try {
      setError(null);
      setLoading(true);

      const response = await axios.post<CognitoCustomerResponse>(
        '/api/customer/auth/cognito/signup',
        customerData,
      );

      if (!response.data || !response.data.message)
        throw new Error('Failed to create customer profile: No response data');

      return response.data;
    } catch (err: any) {
      const errorMessage =
        'There was a problem signing up. Please try again later.';

      logger.error({ err }, errorMessage);
      if (err?.response?.data) return err.response.data;

      return { message: errorMessage, error: 'Error' };
    } finally {
      setLoading(false);
    }
  };

  const cognitoForgotPassword = async (
    email: string,
  ): Promise<CognitoCustomerResponse> => {
    try {
      setLoading(true);
      const res = await axios.post<CognitoCustomerResponse>(
        '/api/customer/auth/cognito/forgot',
        { email },
      );

      if (!res.data || !res.data.message)
        throw new Error('Failed to send forgot password email');

      return res.data;
    } catch (err: any) {
      const errorMessage = 'Error sending forgot password email';

      logger.error({ err }, errorMessage);
      if (err?.response?.data) return err.response.data;

      return { message: errorMessage, error: 'Error' };
    } finally {
      setLoading(false);
    }
  };

  const cognitoResetPassword = async (
    email: string,
    password: string,
    otp: string,
  ): Promise<CognitoCustomerResponse> => {
    try {
      setLoading(true);

      const res = await axios.post<CognitoCustomerResponse>(
        '/api/customer/auth/cognito/reset',
        { email, password, otp },
      );

      if (!res.data || !res.data.message)
        throw new Error('Failed to reset password');

      return res.data;
    } catch (err: any) {
      const errorMessage = 'Error resetting password';

      logger.error({ err }, errorMessage);
      if (err?.response?.data) return err.response.data;

      return { message: errorMessage, error: 'Error' };
    } finally {
      setLoading(false);
    }
  };

  const cognitoVerifyOtp = async (
    email: string,
    otp: string,
  ): Promise<CognitoCustomerResponse> => {
    try {
      setLoading(true);

      const res = await axios.post<CognitoCustomerResponse>(
        '/api/customer/auth/cognito/otp/verify',
        { email, otp },
      );

      if (!res.data || !res.data.message)
        throw new Error('Failed to verify OTP');

      if (!res.data.idToken || !res.data.refreshToken)
        throw new Error('Failed to verify OTP: No tokens returned');

      setCognitoIdToken(res.data.idToken);
      setCognitoRefreshToken(res.data.refreshToken);

      if (res.data.customer) setCustomerData(res.data.customer);
      else throw new Error('Customer not found');

      return res.data;
    } catch (err: any) {
      const errorMessage = 'Error verifying OTP';

      logger.error({ err }, errorMessage);
      if (err?.response?.data) return err.response.data;

      return { message: errorMessage, error: 'Error' };
    } finally {
      setLoading(false);
    }
  };

  const cognitoResendOtp = async (
    email: string,
  ): Promise<CognitoCustomerResponse> => {
    try {
      setLoading(true);

      const res = await axios.post<CognitoCustomerResponse>(
        '/api/customer/auth/cognito/otp/resend',
        { email },
      );

      if (!res.data || !res.data.message)
        throw new Error('Failed to resend OTP');

      return res.data;
    } catch (err: any) {
      const errorMessage = 'Error resending OTP';

      logger.error({ err }, errorMessage);
      if (err?.response?.data) return err.response.data;

      return { message: errorMessage, error: 'Error' };
    } finally {
      setLoading(false);
    }
  };

  const cognitoRefresh = async (): Promise<
    CognitoRefreshTokenResponse | { message: string; error: string }
  > => {
    const refreshToken = cookies[COOKIE_COGNITO_REFRESH_TOKEN];

    if (!refreshToken)
      return { message: 'No refresh token found', error: 'Error' };

    try {
      setLoading(true);
      const res = await axios.post<CognitoRefreshTokenResponse>(
        '/api/customer/auth/cognito/refresh',
        { refreshToken },
      );

      if (!res.data || !res.data.id_token)
        throw new Error('Failed to refresh AWS Cognito tokens');

      setCognitoIdToken(res.data.id_token);

      return res.data;
    } catch (err: any) {
      logger.error({ err }, 'Error refreshing AWS Cognito tokens');

      return { message: 'Error refreshing AWS Cognito tokens', error: 'Error' };
    } finally {
      setLoading(false);
    }
  };

  const cognitoGoogleAuthComplete = async (
    code: string,
  ): Promise<AuthTokens | null> => {
    try {
      setLoading(true);

      const response = await fetch(
        '/api/customer/auth/cognito/google/code-for-token',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code }),
        },
      );

      if (!response.ok) throw new Error(`Failed to exchange code for token.`);

      const data: AuthTokens = await response.json();

      if (data.id_token && data.refresh_token) {
        // Complete Google Auth
        const authResponse = await fetch('/api/customer/auth/cognito/google', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ idToken: data.id_token }),
        });

        if (!authResponse.ok) throw new Error('Auth completion failed.');

        setCognitoIdToken(data.id_token);
        setCognitoRefreshToken(data.refresh_token);
        verifyCognitoCookies(data.id_token);
      }

      return data;
    } catch (err) {
      logger.error({ err }, 'Unable to Complete Google Auth');

      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    // State
    customer,
    anonymousId,
    loading,
    error,

    // Api
    cognitoLogin,
    cognitoSignup,
    cognitoForgotPassword,
    cognitoResetPassword,
    cognitoVerifyOtp,
    cognitoResendOtp,
    cognitoRefresh,
    cognitoGoogleAuthComplete,
    login,
    loginWithGoogle,
    logout,
    forgotPassword,
    resetPassword,
    signup,
    updateAddress,
    fetchCustomerData,
    updateEmailSubscription,
    updateSmsSubscription,
    createAlias,
    isSubscribed,
    createAddress,
    softDeleteAddress,
    updateCustomerAddress,
  };
};

export default useCustomer;
