import { ReebeloConditions } from './types';

export const specificationsToStr = (
  specifications: { key: string; value: string }[] | undefined,
) => (specifications || []).map((s) => `${s.key}: ${s.value}`).join('\n');
export const strToHandle = (str: string | undefined) =>
  str
    ? str
        .trim()
        .toLowerCase()
        .replace('"', ' inch ')
        .replace('&', ' and ')
        .replace('+', ' plus ')
        .split(' ')
        .filter(Boolean)
        .join('-')
    : null;

export const BABY_CATEGORIES = [
  'Baby Gear',
  'Baby',
  'Baby Products',
  'Baby & Toddler Feeding Supplies',
  'Bibs',
  'Breast Pumps',
  'Breastfeeding',
  'Feeding',
  'Pacifiers',
  'Teething',
  'Baby Care Accessories',
  'Baby Monitors',
  'Baby Weighing Scale',
  'Swaddles',
  'Baby Carriers',
  'Baby Carriers Accessories',
  'Baby Health',
  'Baby Toys',
  "Baby's Fashion",
  'Body Suits & Rompers',
  'Bathing',
  'Bathroom Accessories',
  'Bedding',
  'Bedding Accessories',
  'Child Safety Car Seats & Accessories',
  'Baby Travel',
  'Car Seat',
  'Baby & Toddler Car Seat Accessories',
  'Baby Gate',
  'Comforters',
  'Cots',
  'Diapering',
  'Baby Wipe Dispensers & Warmers',
  'Baby Wipes',
  'Changing Mat & Tray Covers',
  'Changing Mats & Trays',
  'Diaper Kits',
  'Diaper Liners',
  'Diaper Organizers',
  'Diaper Pail Accessories',
  'Diaper Pails',
  'Diaper Rash Treatments',
  'Diaper Wet Bags',
  'Diapers',
  'High Chair',
  'Bouncers',
  'High Chair Bundle',
  'Nappy Bags',
  'Nursery Furniture, Bedding & Décor',
  'Baby Blankets',
  'Bassinet',
  'Nursery Care',
  'Nursery Changing',
  'Nursery Decor',
  'Play Mats',
  'Playtime',
  'Nursing Pillow',
  'Prams',
  'Prams Accessories',
  'Pregnancy & Post Partum Care',
  'Rugs',
  'Sleeping Bags & Suit',
  'Solids',
  'Food',
];

export const REEBELO_TO_BABY_CONDITION_MAPPINGS: Record<
  ReebeloConditions,
  string | ReebeloConditions
> = {
  [ReebeloConditions.BrandNew]: 'Over Stock',
  [ReebeloConditions.Premium]: ReebeloConditions.Premium,
  [ReebeloConditions.Pristine]: 'Open Box',
  [ReebeloConditions.Excellent]: ReebeloConditions.Excellent,
  [ReebeloConditions.Good]: ReebeloConditions.Good,
  [ReebeloConditions.Acceptable]: ReebeloConditions.Acceptable,
};

export const isBabyCategory = (category: string) =>
  BABY_CATEGORIES.includes(category);

export function categoryNameToHandle(categoryName: string) {
  // If we were using the same category name everywhere, this wouldn't be necessary.
  if (categoryName === 'Tools') return 'tools-home-improvement';
  if (categoryName === 'Home') return 'home-kitchen';
  if (categoryName === 'Sports') return 'sports-fitness';

  return categoryName
    .toLowerCase()
    .replace(/[^a-z0-9-\s]/g, '') // Exclude hyphens from removal
    .trim()
    .replace(/\s+/g, '-');
}
