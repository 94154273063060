import { Logger } from '@lambda/commons/logging/logger';
import axios from 'axios';
import { ProductCategoryWarranty } from '@lambda/apis/src/category/types';
import settings from '@/settings';

const logger = new Logger('warranty-api');

export const getWarrantyInfoByCategoryPks = async (categoryPks: string[]) => {
  try {
    const response = await axios.post<ProductCategoryWarranty[]>(
      '/api/warranty',
      { categoryPks },
    );

    const warrantyInfoArray = response.data;

    return warrantyInfoArray;
  } catch (err: any) {
    logger.error({ err }, 'error fetching warranty info by category pks');

    return null;
  }
};

export const formatWarrantyInfo = (
  warrantyInfo?: ProductCategoryWarranty | null,
  mixedCart?: boolean,
) => {
  // TODO: Placeholder logic for now until we determine products that are actually not eligible for warranty
  if (
    !warrantyInfo ||
    !warrantyInfo.eligible ||
    !warrantyInfo.warrantyLength ||
    !warrantyInfo.warrantyUnit
  )
    return `${mixedCart ? 'Up to ' : ''}${settings.warranty_month} months`;

  const { warrantyUnit } = warrantyInfo;
  const { warrantyLength } = warrantyInfo;

  const formattedString = `${
    mixedCart ? 'Up to ' : ''
  }${warrantyLength} ${warrantyUnit}`;

  if (warrantyLength > 1) return `${formattedString}s`;

  return formattedString;
};

export const normalizeWarrantyLength = (
  length?: number,
  unit?: string,
): number => {
  if (!unit || !length) return 0;

  switch (unit) {
    case 'day':
      return length;
    case 'month':
      return length * 30; // Approximation: 1 month = 30 days
    case 'year':
      return length * 365; // Approximation: 1 year = 365 days
    default:
      return 0;
  }
};
